<script>
  import { get } from 'vuex-pathify'
  import screen from '@/helpers/screen'
  import AccountProfileBox from '@/components/account/AccountProfileBox'
  import AlltruePlusInfoModal from '@/components/account/AlltruePlusInfoModal'

  export default {
    components: {
      AccountProfileBox,
      AlltruePlusInfoModal,
    },
    data() {
      return {
        creditsRef: null,
        alltrueModalVisible: false,
      }
    },
    computed: {
      ...get(['route']),
      ...get('account', [
        'username',
        'fullName',
        'phoneNumber',
        'addresses',
        'billingInfo',
        'accountCredit',
        'isAlltruePlusSubscriber',
      ]),
      screen,
    },
    // this is if you're already in the settings page and you click on 'credits' in the nav
    watch: {
      $route() {
        this.scrollToCredits()
      },
    },
    methods: {
      scrollToCredits() {
        if (this.$route.params.section === 'credits') {
          this.creditsRef.scrollIntoView({ behavior: 'smooth' })
        }
      },
    },
  }
</script>

<template>
  <div role="tabpanel" aria-label="Settings">
    <BaseStack gap-size="lg">
      <AccountProfileBox>
        <template v-slot:heading>Account Info</template>
        <template v-slot:action>
          <BaseLinkStyled :to="{ name: 'AccountProfileSettingsEditInfo' }" color="black">
            Edit
          </BaseLinkStyled>
        </template>
        <BaseAttributeValueList gap-size="lg">
          <BaseAttributeValueListItem>
            <template v-slot:attribute>Name</template>
            <template v-slot:value>{{ fullName }}</template>
          </BaseAttributeValueListItem>
          <BaseAttributeValueListItem>
            <template v-slot:attribute>Email</template>
            <template v-slot:value>{{ username }}</template>
          </BaseAttributeValueListItem>

          <BaseAttributeValueListItem>
            <template v-slot:attribute>Password</template>
            <template v-slot:value>
              <BaseLinkStyled :to="{ name: 'AccountProfileSettingsChangePassword' }">
                Change your password
              </BaseLinkStyled>
            </template>
          </BaseAttributeValueListItem>

          <BaseAttributeValueListItem>
            <template v-slot:attribute>Phone Number</template>
            <template v-slot:value>
              <span v-if="phoneNumber">{{ phoneNumber }}</span>
              <BaseLinkStyled v-else :to="{ name: 'AccountProfileSettingsEditInfo' }">
                Add to get notified
              </BaseLinkStyled>
            </template>
          </BaseAttributeValueListItem>
        </BaseAttributeValueList>
      </AccountProfileBox>

      <AccountProfileBox>
        <template v-slot:heading>Shipping Addresses</template>
        <template v-slot:action>
          <BaseLinkStyled :to="{ name: 'AccountProfileSettingsAddAddress' }" color="black">
            <span class="hidden md:inline">Add</span> New Address
          </BaseLinkStyled>
        </template>
        <ul class="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 gap-6">
          <li v-for="address in addresses" :key="address.id">
            <BaseAddress :address="address" include-name />
            <BaseLinkStyled
              :to="{
                name: 'AccountProfileSettingsEditAddress',
                params: { addressId: address.id },
              }"
              color="black"
            >
              Edit </BaseLinkStyled
            ><span class="px-3 pointer-events-none">&middot;</span
            ><BaseLinkStyled
              :to="{
                name: 'AccountProfileSettingsRemoveAddress',
                params: { addressId: address.id },
              }"
              color="black"
            >
              Remove
            </BaseLinkStyled>
          </li>
        </ul>
      </AccountProfileBox>

      <AccountProfileBox>
        <template v-slot:heading>Payment Method</template>
        <template v-slot:action>
          <BaseLinkStyled :to="{ name: 'AccountProfileSettingsPaymentMethod' }" color="black">
            {{ billingInfo ? 'Change' : 'Add' }}
            <span class="hidden md:inline">Payment Method</span>
          </BaseLinkStyled>
        </template>
        <BaseBillingInfo v-if="billingInfo" :billing-info="billingInfo" />
        <div v-else>No payment method</div>
      </AccountProfileBox>

      <!-- If a user clicked on 'credits' from a different page, this will scroll the page to the credits box -->
      <AccountProfileBox v-mounted="scrollToCredits">
        <template v-slot:heading>Credits</template>
        <template v-slot:action>
          <!--<BaseLinkStyled color="black">Redeem Gift Card</BaseLinkStyled>-->
        </template>
        <div v-ref="creditsRef" class="font-medium">
          Total Available Credits: {{ $formatPrice(accountCredit / 100) }}
        </div>
        <div>Credits are automatically applied in your cart.</div>
      </AccountProfileBox>

      <AccountProfileBox v-if="isAlltruePlusSubscriber">
        <template v-slot:heading>
          Alltrue+ Perks
          <BaseIcon :size="5" class="mb-6px align-middle inline-block">
            <IconBullhorn />
          </BaseIcon>
        </template>
        <template v-slot:action>
          <BaseLinkStyled color="black" @click="alltrueModalVisible = true">
            Learn more
          </BaseLinkStyled>
        </template>
        <div class="max-w-105">
          <p>
            <span class="font-semibold">Alltrue+ Market Discount ($10/season)</span> <br />
            Your $10 discount will be automatically applied to your order at checkout. Your discount
            can be used in our seasonal Add&#8209;On Market or in our Essentials Shop.
          </p>
          <p class="my-6">
            <span class="font-semibold">Alltrue+ T&#8209;shirt</span> <br />
            Your members-only T&#8209;shirt will be sent in your next core box. Email
            <BaseLinkStyled href="mailto:help@alltrue.com">help@alltrue.com</BaseLinkStyled> if you
            have questions.
          </p>
          <p>
            <span class="font-semibold">Extra Product in Every Box ($30+ value)</span> <br />
            It’s a surprise! Your extra product will be sent in your <br v-if="screen.md" />
            next core box.
          </p>
        </div>
      </AccountProfileBox>
    </BaseStack>

    <Portal to="modal">
      <BaseModal
        v-if="screen.md"
        bg-color="dawn"
        size="sm"
        :open="route.name !== 'AccountProfileSettings'"
        dismissible
        @dismiss="$navigate({ name: 'AccountProfileSettings' })"
      >
        <BaseTransitionView no-scroll>
          <RouterView />
        </BaseTransitionView>
      </BaseModal>
      <AlltruePlusInfoModal :open="alltrueModalVisible" @dismiss="alltrueModalVisible = false" />
    </Portal>
  </div>
</template>
